/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFirebase } from '../hooks/firebase';

import TopNavBar from '../components/Navigation/TopNavBar';
import { useDationUser } from '../hooks/dation-user';

import getRouter from '../components/Router';

import AnonUserRouter from '../components/Router/AnonUserRouter';
import ToastHandler from '../components/Toast/ToastHandler';
import * as ROUTES from '../constants/routes';
import { useApi } from '../hooks/use-api';
import { ProvideViewPreferences } from '../hooks/view-preferences';
import { sendPageView } from '../utils/googleAnalytics';

function MainPage() {
	const firebase = useFirebase();
	const dationUser = useDationUser();
	const navigate = useNavigate();
	const location = useLocation();

	const [isLoading, setIsLoading] = useState(true);

	const [, verifyProfile] = useApi.get(
		ROUTES.VERIFY_PROFILE,
		{},
		{ lazy: true },
	);

	useEffect(
		() => firebase.onAuthStateChanged(
			firebase.auth,
			async user => {
				if(user) {
					await firebase.initializeIdToken();
					if(firebase.userSignUp) {
						await firebase.doPasswordUpdate(firebase.userPassword);
						await verifyProfile();
						await navigate(ROUTES.LANDING);
						firebase.setUserPassword(null);
						firebase.setUserSignUp(false);
					} else {
					// Initialize firebase user and token
						await firebase.setAuthUser(user);
						await dationUser.initializeDationUser();
					}
				} else if(firebase.authUser !== null) {
					firebase.setAuthUser(null);
					dationUser.setUser(null);
				}
				setIsLoading(false);
			},
		),
		[firebase.userSignUp],
	);

	useEffect(() => {
		sendPageView(location);
	}, [location]);

	if(isLoading) {
		return <div className="loading-spinner" />;
	}

	const getUserRole = () => {
		const { roles } = dationUser.user || [];
		return roles ? roles[0] : 'anon';
	};

	const handleSignOut = () => {
		firebase.doSignOut().then(
			() => {
				dationUser.setCurrentCompanyId('');
			},
		);
	};

	return firebase.authUser ? (
		<ProvideViewPreferences>
			<TopNavBar />
			<ToastHandler />
			<div className="container-fluid pb-0">
				{getRouter(getUserRole(), { handleSignOut })}
			</div>
		</ProvideViewPreferences>
	)
		: (
			<div className="auth-wrapper">
				<div className="auth-inner">
					<AnonUserRouter />
				</div>
			</div>
		);
}

export default MainPage;
