/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
	Card, Accordion, Col, Form, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from '../../moment';
import useApi from '../../hooks/use-api';
import * as ROUTES from '../../constants/routes';
import DateInput from '../Form/DateInput';
import { sendGoogleAnalyticsEvent } from '../../utils/googleAnalytics';
import ErrorMessage from '../common/messages/ErrorMessage';
import SuccessMessage from '../common/messages/SuccessMessage';
import { calculateCode95CyclePoints } from '../../utils/code95';

function Code95Cycle({
	cycle, accordionKey, studentId, setDisabled, onCycleSubmit,
}) {
	const initialStart = moment();
	const initialEnd = moment('+5 years');
	const { t } = useTranslation();
	const [start, setStart] = useState(initialStart);
	const [end, setEnd] = useState(initialEnd);
	const [errorMessage, setErrorMessage] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null);

	const {
		startDate,
		endDate,
		id,
		theoryHours,
		practiceHours,
	} = cycle;

	let submit;
	let eventOptions = null;
	if(id) {
		const [state, patch] = useApi.patch(ROUTES.updateCode95Cycle(id));
		submit = patch;
	} else {
		const [state, post] = useApi.post(ROUTES.postCode95Cycle());
		submit = post;
		eventOptions = {
			hitType: 'event',
			eventCategory: 'Code95Cycle',
			eventAction: 'Create',
		};
	}

	const onSubmit = () => {
		if(!moment.isMoment(start) || !moment.isMoment(end)) {
			setErrorMessage(t('code95.date_format_error'));
			return;
		}

		const newCycle = {
			startDate: start.format(),
			endDate: end.format(),
			...(!id ? { student: { id: studentId } } : {}),
		};

		setDisabled(true);
		submit(newCycle)
			.then(cycleResponse => {
				if(eventOptions) {
					sendGoogleAnalyticsEvent(eventOptions);
				}
				setErrorMessage(null);
				setSuccessMessage(t('code95.cycle_submitted'));
				setDisabled(false);
				onCycleSubmit && onCycleSubmit({
					...cycleResponse,
					newCycleCounter: cycle.newCycleCounter || undefined,
				});
			}).catch(error => {
				setSuccessMessage(null);
				setErrorMessage(error);
				setDisabled(false);
			});
	};

	useEffect(() => {
		setStart(moment(startDate));
		setEnd(moment(endDate));
	}, [startDate, endDate]);

	const isPracticeHoursComplete = practiceHours >= 7;
	const isCycleComplete = theoryHours + practiceHours >= 35 && isPracticeHoursComplete;

	return (
		// Set overflow to visible, otherwise datepicker will only be half in view
		<Card style={{ overflow: 'visible' }}>
			<Accordion.Toggle as={Card.Header} className="d-flex justify-content-between" eventKey={accordionKey}>
				{(moment.isMoment(start) && moment.isMoment(end)) && t('code95.modal_title', { start: start?.format('L'), end: end?.format('L') })}
				{isCycleComplete ? (
					<span className="glyphicons glyphicons-sync-check text-success" style={{ fontSize: '1.5rem' }} />
				) : (
					<span className="glyphicons glyphicons-sync-alert text-danger" style={{ fontSize: '1.5rem' }} />
				)}
			</Accordion.Toggle>
			<Accordion.Collapse eventKey={accordionKey}>
				<Card.Body>
					<ErrorMessage message={errorMessage} />
					<SuccessMessage message={successMessage} />
					<strong>{t('code95.period')}</strong>
					<Form.Row>
						<Col xs={5}>
							<DateInput onChange={startNew => setStart(startNew)} value={start} />
						</Col>
						<Col xs={2} className="d-flex justify-content-center align-items-center">
							{t('code95.until')}
						</Col>
						<Col xs={5}>
							<DateInput onChange={endNew => setEnd(endNew)} value={end} />
						</Col>
					</Form.Row>
					<br />
					<strong>{t('code95.state')}</strong>
					<p>{t('code95.hours_achieved', { hours: calculateCode95CyclePoints(cycle) })}</p>
					<div className="d-flex">
						<div>{t('employees.practical')}:</div>
						{isPracticeHoursComplete ? (
							<div className="py-1 px-3"><span className="glyphicons glyphicons-square-empty-check text-success" /></div>
						) : (
							<div className="py-1 px-3"><span className="glyphicons glyphicons-square-empty-remove text-danger" /></div>
						)}
					</div>
					<div className="d-flex flex-row-reverse">
						<Button variant="primary" className="mt-auto" onClick={() => onSubmit()}>{t('code95.save')}</Button>
					</div>
				</Card.Body>
			</Accordion.Collapse>
		</Card>
	);
}

Code95Cycle.propTypes = {
	cycle: PropTypes.shape({
		startDate: PropTypes.string.isRequired,
		endDate: PropTypes.string.isRequired,
		id: PropTypes.number,
		theoryHours: PropTypes.number,
		practiceHours: PropTypes.number,
		newCycleCounter: PropTypes.number,
	}),
	accordionKey: PropTypes.number.isRequired,
	studentId: PropTypes.number.isRequired,
	setDisabled: PropTypes.func.isRequired,
	onCycleSubmit: PropTypes.func,
};
export default Code95Cycle;
